import React from 'react';
import { Drawer, Form, Input, Button, Upload, Icon, Select, Radio, message, Tooltip } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import { getCountryCallingCode } from 'libphonenumber-js/min';
import { removeCommaFromEnd } from '../../Utils/TextUtils';
import { getAddCandidateDrawerVisibleStatus } from '../../Reducers/AddCandidateReducer';
import { getJobUtilities } from '../../Reducers/JobReducer';
import * as AddCandidateActions from '../../Actions/AddCandidateActions';
import * as LocationSearchActions from '../../Actions/LocationSearchActions';
import * as CandidateActions from '../../Actions/CandidateActions';
import * as JobActions from '../../Actions/JobActions';
import styles from './AddCandidateContainer.module.scss';

const mapStateToProps = state => ({
  visible: getAddCandidateDrawerVisibleStatus(state),
  utilities: getJobUtilities(state),
});

const mapDispatchToProps = {
  toggleAddCandidateDrawerVisibleStatus: AddCandidateActions.toggleAddCandidateDrawerVisibleStatus,
  fetchLocations: LocationSearchActions._fetchLocations,
  fetchJobs: JobActions._fetchJobs,
  createCandidate: CandidateActions.createCandidate,
  changeCandidateStatus: CandidateActions.changeCandidateStatus,
};

const { Option } = Select;

const AddCandidateContainer = props => {
  const {
    visible,
    toggleAddCandidateDrawerVisibleStatus,
    form,
    utilities,
    fetchJobs,
    version,
    fetchLocations,
    createCandidate,
    changeCandidateStatus,
    location,
  } = props;
  const [sourceDropdown, setSourceDropdown] = React.useState('');
  const [fetchJobsApiStatus, setFetchJobsApiStatus] = React.useState();
  const [createCandidateApiStatus, setCreateCandidateApiStatus] = React.useState();
  const [fetchLocationApiStatus, setFetchLocationApiStatus] = React.useState();
  const [fetchedLocations, setFetchedLocations] = React.useState([]);
  const [otherSource, setOtherSource] = React.useState('');
  const [resume, setResume] = React.useState([]);
  const [resumeContent, setResumeContent] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [jobContext, setJobContext] = React.useState('Job');

  const resetFormFields = () => {
    form.resetFields();
    setSourceDropdown('');
    setOtherSource('');
    setResume([]);
    setResumeContent(null);
    setJobContext('Job');
    setFetchJobsApiStatus(undefined);
    setFetchLocationApiStatus(undefined);
    setOptions([]);
    setCreateCandidateApiStatus(undefined);
    setFetchedLocations([]);
  };

  const extractIdFromUrl = () => {
    const pathParts = location.pathname.split('/');
    let urlJobContext = '';
    const jobsIndex = pathParts.indexOf('jobs');
    const segmentsIndex = pathParts.indexOf('segments');

    if (jobsIndex !== -1 && jobsIndex + 1 < pathParts.length) {
      const id = pathParts[jobsIndex + 1];
      urlJobContext = 'Job';
      return !isNaN(id) ? { id: Number(id), urlJobContext } : null;
    }

    if (segmentsIndex !== -1 && segmentsIndex + 1 < pathParts.length) {
      const id = pathParts[segmentsIndex + 1];
      urlJobContext = 'Segment';
      return !isNaN(id) ? { id: Number(id), urlJobContext } : null;
    }

    return null;
  };

  const { id: jobIdFromUrl, urlJobContext } = extractIdFromUrl() ?? {};

  React.useEffect(() => {
    if (jobIdFromUrl && urlJobContext) {
      fetchAndJobId(jobIdFromUrl, urlJobContext);
    }
  }, [location.pathname, jobIdFromUrl]);

  const onCloseDrawer = () => {
    resetFormFields();
    toggleAddCandidateDrawerVisibleStatus(false);
  };

  const onChangeJobContext = e => {
    if (jobContext !== e.target.value) {
      setOptions([]);
      form.setFieldsValue({ jobId: undefined });
      setJobContext(e.target.value);
    }
  };

  const fetchJobsOrLists = async (searchTerm, _destinationName) => {
    const payload =
      _destinationName === 'Segment'
        ? {
            count: 10,
            skip: 0,
            statusId: 1,
            searchTerm,
            EntityType: 'Segment',
            isAssigned: true,
          }
        : {
            count: 10,
            skip: 0,
            statusId: 1,
            searchTerm,
            isAssigned: true,
          };
    const result = await fetchJobs(payload);
    const updatedResult = result?.Jobs ?? [];
    setOptions(
      (updatedResult ?? []).map(job => ({
        value: job.JobId,
        text: `${job.JobTitle} (Id: ${version !== 'ats' || jobContext === 'Segment' ? job.JobId : job.JobCode}) ${
          jobContext === 'Job' ? '|' : ''
        } ${
          jobContext === 'Segment' || job.Location === undefined || job.Location === ''
            ? ''
            : removeCommaFromEnd(job.Location)
        } ${jobContext === 'Segment' || job.Location === undefined || job.Location === '' ? '' : ','} ${
          jobContext === 'Job' ? job.CountryCode : ''
        } `,
      }))
    );
  };
  const fetchAndJobId = async (id, _destinationName) => {
    const payload =
      _destinationName === 'Segment'
        ? {
            count: 10,
            skip: 0,
            statusId: 1,
            searchTerm: id,
            EntityType: 'Segment',
            isAssigned: true,
          }
        : {
            count: 10,
            skip: 0,
            statusId: 1,
            searchTerm: id,
            isAssigned: true,
          };
    const result = await fetchJobs(payload);
    if (result?.Jobs?.length > 0) {
      const job = result.Jobs[0];
      const option = {
        value: job.JobId,
        text: `${job.JobTitle} (Id: ${version !== 'ats' || jobContext === 'Segment' ? job.JobId : job.JobCode}) ${
          jobContext === 'Job' ? '|' : ''
        } ${
          jobContext === 'Segment' || job.Location === undefined || job.Location === ''
            ? ''
            : removeCommaFromEnd(job.Location)
        } ${jobContext === 'Segment' || job.Location === undefined || job.Location === '' ? '' : ','} ${
          jobContext === 'Job' ? job.CountryCode : ''
        }`,
      };
      setOptions([option]);
      setJobContext(_destinationName);
      form.setFieldsValue({ jobId: job.JobId });
    }
  };

  const onSearch = debounce(async (value, _destinationName) => {
    if (value.length) {
      setFetchJobsApiStatus('INPROGRESS');
      await fetchJobsOrLists(value, _destinationName);
      setFetchJobsApiStatus('COMPLETED');
    }
  }, 600);

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields(['resume'], {}, resumeErrors => {
      console.log('resume error found!', resumeErrors);
      if (resumeErrors) {
        form.validateFields();
      }
    });
    form.validateFields(async (err, values) => {
      if (!err) {
        const { email, fullName, location, phone, prefix, resume, sources } = values;
        const country = location.split('-')[location.split('-').length - 1];
        const city = location.split('-')[0];
        const todayDate = new Date(Date.now());
        const fileList = resume.fileList[0];

        const payload = {
          Emails: [email],
          Resumes: [
            {
              Content: resumeContent.split(',')[1],
              Name: fileList.name,
              Length: fileList.size,
              MediaType: fileList.type,
              Date: fileList.lastModifiedDate,
            },
          ],
          Name: fullName,
          Location: city,
          Country: country,
          Sources: [
            {
              Portal: 'Internal',
              Type: 'Primary',
            },
            {
              Portal: 'Internal',
              Type: 'Original',
            },
          ],
          NewCandidateSource: sources === 'Others' ? otherSource : sources,
          ProfileDate: todayDate.toISOString(),
          LatestResumeDate: todayDate.toISOString(),
          LastActivityDate: todayDate.toISOString(),
        };
        if (phone) {
          payload.Phones = [`${prefix}${phone}`];
        }
        try {
          setCreateCandidateApiStatus('INPROGRESS');
          const responseData = await createCandidate(payload);
          if (values.jobId) {
            const postData = {
              candidateId: responseData.id,
              status: 'Shortlisted',
              jobId: values.jobId,
              mustHaves: [],
            };
            await changeCandidateStatus(postData, null, true, jobContext.toLowerCase());
          }
          setCreateCandidateApiStatus('COMPLETED');
          onCloseDrawer();
        } catch {
          setCreateCandidateApiStatus('FAILED');
          onCloseDrawer();
        }
      }
    });
  };

  const handleLocationSearch = debounce(async value => {
    if (value.length) {
      setFetchLocationApiStatus('INPROGRESS');
      const _locationSuggestions = await fetchLocations({ filter: { country: 'US', search: value, size: 10 } });
      setFetchedLocations(_locationSuggestions);
      setFetchLocationApiStatus('COMPLETED');
    }
  }, 600);

  const { getFieldDecorator } = form;
  const countries = _.get(utilities, 'countries', false) || [];
  const countriesMenu = countries
    .sort((c1, c2) => {
      if (c1.Name.toLowerCase() < c2.Name.toLowerCase()) {
        return -1;
      }
      if (c2.Name.toLowerCase() < c1.Name.toLowerCase()) {
        return 1;
      }
      return 0;
    })
    .map(country => {
      try {
        const countryCallingCode = getCountryCallingCode(country.Iso2Code);
        return (
          <Option
            key={countryCallingCode}
            value={countryCallingCode}
          >{`(+${countryCallingCode}) ${country.Name}`}</Option>
        );
      } catch (error) {
        return null;
      }
    });
  const prefixSelector = getFieldDecorator('prefix', {
    initialValue: '1',
  })(
    <Select className={styles.countryDropdown} getPopupContainer={node => node.parentNode}>
      {countriesMenu}
    </Select>
  );

  const sourceList = ['Monster', 'LinkedIn', 'Indeed', 'Career Builder', 'Others'];

  const dropDownStatus = (apiStatus, notFoundText) => {
    return apiStatus === 'INPROGRESS' ? <p>Loading...</p> : <p>{notFoundText}</p>;
  };

  const beforeUpload = file => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result;
        setResumeContent(base64);
        resolve(false);
      };
    });
  };

  const removeResume = () => {
    setResume([]);
    setResumeContent(null);
  };

  const handleUpload = ({ file, fileList }) => {
    if (file.status === 'removed') {
      setResume(fileList.filter(item => item.uid !== file.uid));
      setTimeout(() => form.validateFields(['resume'], {}), 0);
    } else {
      const validExtensions = ['.doc', '.docx', '.pdf', '.txt', '.html'];
      const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
      if (!validExtensions.includes(fileExtension)) {
        message.error('File format is not supported');
        return;
      }
      const updatedList = fileList.map(item => ({
        ...item,
        status: 'done',
      }));
      setResume(updatedList);
      setTimeout(() => form.validateFields(['resume'], {}), 0);
    }
  };

  const handleOtherSource = e => {
    setOtherSource(e.target.value);
    form.setFieldsValue({ sources: form.getFieldValue('sources') });
    form.validateFields(['sources']);
  };

  return (
    <div>
      <Drawer
        visible={visible}
        width={580}
        onClose={onCloseDrawer}
        title="Add Candidate"
        className={styles.addCandidateDrawer}
        bodyStyle={{ padding: '4px 0px 0px' }}
      >
        <Form onSubmit={handleSubmit} className={styles.form}>
          <Form.Item label="Resume" name="resume" className={styles.formItem}>
            {getFieldDecorator('resume', {
              rules: [
                {
                  required: true,
                  message: 'Please upload a resume',
                },
                {
                  validator: (rule, { file, fileList }, callback) => {
                    if (!resume || resume.length === 0) {
                      callback('Please upload a resume');
                      return;
                    }
                    if (resume[0].size / 1024 > 2048) {
                      callback('File cant be larger than 2 mb');
                      return;
                    }
                    callback();
                  },
                },
              ],
            })(
              <Upload.Dragger
                beforeUpload={beforeUpload}
                accept=".doc,.docx,.pdf,.txt,.html"
                customRequest={({ file, onSuccess }) => {
                  setTimeout(() => onSuccess('ok'), 500);
                }}
                className={styles.uploadResume}
                fileList={resume}
                onChange={handleUpload}
                onRemove={removeResume}
                showUploadList={{
                  showDownloadIcon: false,
                  showRemoveIcon: true,
                }}
              >
                <p className={styles.uploadText}>
                  <Icon type="upload" /> Upload Resume
                </p>
              </Upload.Dragger>
            )}
            <p className={styles.uploadHintText}>
              Maximum file size: 2MB | Accepted formats: doc, docx, pdf, text, html
            </p>
          </Form.Item>
          <Form.Item label="Full Name" className={styles.formItem}>
            {getFieldDecorator('fullName', {
              rules: [
                {
                  required: true,
                },
                {
                  validator: (rule, value, callback) => {
                    if (!value) {
                      callback('Please enter the full name');
                    }
                    const nameRegex = /^[A-Za-z\s\-']+$/;
                    if (!nameRegex.test(value)) {
                      callback("Name can only contain letters, spaces, hyphens (-), and apostrophes (')");
                    }
                    callback();
                  },
                },
              ],
            })(<Input placeholder="Robert Baratheon" />)}
          </Form.Item>
          <Form.Item label="Email Address" className={styles.formItem}>
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid email',
                },
                {
                  required: true,
                  message: 'Please enter the email',
                },
              ],
            })(<Input placeholder="robertbaratheon@gmail.com" />)}
          </Form.Item>
          <Form.Item label="Location" className={styles.formItem}>
            {getFieldDecorator('location', {
              rules: [
                {
                  required: true,
                  message: 'Please enter the location',
                },
              ],
            })(
              <Select
                allowClear
                showSearch
                filterOption={false}
                showArrow={false}
                notFoundContent={dropDownStatus(fetchLocationApiStatus, 'No Data')}
                onSearch={value => handleLocationSearch(value)}
                getPopupContainer={node => node.parentNode}
                placeholder="City, state, country"
                className={styles.contextSearch}
              >
                {fetchedLocations.map(location => (
                  <Option
                    key={`${location.City}-${location.State}-${location.CountryCode}`}
                    value={`${location.City}-${location.State}-${location.CountryCode}`}
                  >
                    {location?.City && location.City}, {location.State}, {location.CountryCode}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Phone Number" className={styles.formItem}>
            {getFieldDecorator('phone', {
              rules: [
                {
                  validator: (rule, value, callback) => {
                    const digitsOnly = value.replace(/\D/g, '');

                    if (digitsOnly.length > 13) {
                      callback('Phone number cannot exceed 13 digits');
                    }

                    if (!/^[0-9]{7,13}$/.test(digitsOnly)) {
                      callback('Please enter a valid phone number');
                    }
                    callback();
                  },
                },
              ],
            })(<Input addonBefore={prefixSelector} placeholder="(555) 555-1234" />)}
          </Form.Item>
          <Form.Item label="Sources" className={styles.formItem}>
            {getFieldDecorator('sources', {
              rules: [
                { required: true, message: 'Please enter the source' },
                {
                  validator: (rule, value, callback) => {
                    if (value === 'Others' && !otherSource) {
                      callback('Please enter the other source');
                      return;
                    }
                    callback();
                  },
                },
              ],
            })(
              <Select
                className={styles.sources}
                onChange={val => setSourceDropdown(val)}
                placeholder="Monster"
                getPopupContainer={node => node.parentNode}
              >
                {sourceList.map(source => (
                  <Option key={source} value={source}>
                    {source}
                  </Option>
                ))}
              </Select>
            )}
            {sourceDropdown === 'Others' && (
              <Input
                placeholder="Enter Source"
                onChange={handleOtherSource}
                className={styles.otherSource}
                value={otherSource}
              />
            )}
          </Form.Item>
          <Form.Item label="Add To" className={styles.formItem}>
            <Radio.Group onChange={onChangeJobContext} value={jobContext}>
              <Radio.Button shape="round" value="Job" className={styles.contextButton}>
                Job
              </Radio.Button>
              <Radio.Button shape="round" value="Segment" className={styles.contextButton}>
                Segment
              </Radio.Button>
            </Radio.Group>
            {getFieldDecorator('jobId')(
              <Select
                allowClear
                showSearch
                filterOption={false}
                showArrow={false}
                notFoundContent={dropDownStatus(fetchJobsApiStatus, `${jobContext} not found `)}
                onSearch={value => onSearch(value, jobContext)}
                getPopupContainer={node => node.parentNode}
                placeholder="Search"
                className={styles.contextSearch}
              >
                {options?.map(option => (
                  <Option key={option.value} value={option.value}>
                    {option.text}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <div className={styles.footer}>
            <Button onClick={onCloseDrawer} className={styles.cancel} shape="round">
              Cancel
            </Button>
            <Form.Item>
              <Tooltip title={resume.length === 0 ? 'Upload a resume' : ''}>
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  disabled={resume.length === 0}
                  loading={createCandidateApiStatus === 'INPROGRESS'}
                >
                  Save
                </Button>
              </Tooltip>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(AddCandidateContainer));
