import React from 'react';
import { Button, Form } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { InternalIcon } from '../../Icons/AryaIcons';
import JobCandidateListTabs from '../JobCandidateListTabs/JobCandidateListTabs';
import ManualSearchContainer from '../ManualSearchV2/ManualSearchContainer/ManualSearchContainer';
import JobCandidatesTab from '../JobCandidatesTab/JobCandidatesTab';
import { getUsersById } from '../../Reducers/UserReducer';
import { getAssociateCandidateModalStatus } from '../../Reducers/AddCandidateReducer';
import * as JobActions from '../../Actions/JobActions';
import * as AddCandidateActions from '../../Actions/AddCandidateActions';
import * as CandidateActions from '../../Actions/CandidateActions';
import ManualSearchWrapper from '../ManualSearchV2/ManualSearchWrapper/ManualSearchWrapper';
import styles from './InternalCandidateList.module.scss';
import AssociateCandidateModal from './AssociateCandidateModal';

const mapStateToProps = state => ({
  usersById: getUsersById(state),
  associateCandidateModalStatus: getAssociateCandidateModalStatus(state),
});

const mapDispatchToProps = {
  fetchJobs: JobActions._fetchJobs,
  toggleAddCandidateDrawerVisibleStatus: AddCandidateActions.toggleAddCandidateDrawerVisibleStatus,
  toggleAssociateCandidateModalStatus: AddCandidateActions.toggleAssociateCandidateModalStatus,
  bulkCandidateTransfer: CandidateActions.bulkCandidateTransfer,
  bulkCandidateRecommend: CandidateActions.bulkCandidateRecommend,
  bulkSegmentCandidateRecommend: CandidateActions.bulkSegmentCandidateRecommend,
  changeCandidateStatus: CandidateActions.changeCandidateStatus,
};

const CandidateList = props => {
  const {
    history,
    location,
    usersById,
    toggleAddCandidateDrawerVisibleStatus,
    associateCandidateModalStatus,
    toggleAssociateCandidateModalStatus,
    fetchJobs,
    bulkCandidateTransfer,
    bulkCandidateRecommend,
    bulkSegmentCandidateRecommend,
    changeCandidateStatus,
  } = props;
  const [isSearchPerformed, setIsSearchPerformed] = React.useState(false);
  const [status, setStatus] = React.useState('Sourced');

  const setSearchPerformed = value => setIsSearchPerformed(value);
  const manualSearchDefaultValues = {
    SourcingLocations: [
      { CountryCode: 'US', Distance: 0, DistanceUnit: 'Miles', IsPrimaryLocation: true, Label: 'US', Region: 'US' },
    ],
  };

  const setUrl = url => {
    history.push(url);
  };

  const onAddCandidate = () => toggleAddCandidateDrawerVisibleStatus(true);

  return (
    <div className={styles.candidateListContainer}>
      <div className={styles.header}>
        <div>
          <h2>
            <InternalIcon className={styles.internalIcon} /> Internal Candidates
          </h2>
        </div>
        <div>
          <Button shape="round" onClick={onAddCandidate}>
            Add Candidate
          </Button>
        </div>
      </div>
      <ManualSearchWrapper>
        <JobCandidatesTab
          setUrl={setUrl}
          currentJobDetails={{}}
          candidateContext="candidateCreation"
          jobGuid={null}
          status={status}
          connectionStatus={null}
          publishedStatus={null}
          setStatus={setStatus}
          setConnectionStatus={null}
          setPublishedStatus={null}
          location={location}
          changeTabStatus={null}
          usersById={usersById}
          manualSearchDefaultValues={manualSearchDefaultValues}
        />
        <AssociateCandidateModal
          associateCandidateModalStatus={associateCandidateModalStatus}
          toggleAssociateCandidateModalStatus={toggleAssociateCandidateModalStatus}
          fetchJobs={fetchJobs}
          bulkCandidateTransfer={bulkCandidateTransfer}
          bulkCandidateRecommend={bulkCandidateRecommend}
          bulkSegmentCandidateRecommend={bulkSegmentCandidateRecommend}
          changeCandidateStatus={changeCandidateStatus}
        />
      </ManualSearchWrapper>
    </div>
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CandidateList));
