import { defineMessages } from 'react-intl';

const messages = defineMessages({
  jobs: {
    id: 'Navbar.jobs',
    defaultMessage: 'Jobs',
  },
  connect: {
    id: 'Navbar.connect',
    defaultMessage: 'Connect',
  },
  help: {
    id: 'Navbar.help',
    defaultMessage: 'Help',
  },
  calendar: {
    id: 'Navbar.calendar',
    defaultMessage: 'Calendar',
  },
  reports: {
    id: 'Navbar.reports',
    defaultMessage: 'Reports',
  },
  research: {
    id: 'Navbar.research',
    defaultMessage: 'Research',
  },
  unassignedJobs: {
    id: 'Navbar.unassignedJob',
    defaultMessage: 'Unassigned Jobs',
  },
  buyPlan: {
    id: 'Navbar.buyPlan',
    defaultMessage: 'Buy Jobs',
  },
  segments: {
    id: 'Navbar.segments',
    defaultMessage: 'Segments',
  },
  more: {
    id: 'Navbar.more',
    defaultMessage: 'More',
  },
});

export default messages;
