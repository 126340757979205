import React from 'react';
import { connect } from 'react-redux';
import AvailableContactInformation from '../../Components/CandidateContacts/AvailableContactInformation';
import { getCandidatesConnectInfo, getCandidateConnectStatus } from '../../Reducers/ConnectReducer';
import * as connectActions from '../../Actions/ConnectActions';
import { getUsersByGuId } from '../../Reducers/UserReducer';
import { getCandidateWorkflowHistory } from '../../Reducers/WorkflowWindowReducer';
import { setNotification } from '../../Actions/ActionCreators/ConnectActions';

const mapStateToProps = (state, props) => ({
  usersById: getUsersByGuId(state),
  connectInfo: getCandidatesConnectInfo(state),
  candidateConnectStatus: getCandidateConnectStatus(state, props.candidate?.PersonId),
  candidateWorkflowHistory: getCandidateWorkflowHistory(state, props.candidate.Id),
});

const mapDispatchToProps = {
  addContact: connectActions.addContact,
  getRefundForInvalidContacts: connectActions.getRefundForInvalidContacts,
  handleValidityStatusUpdate: connectActions.updateEmailMismatchStatus,
  handlePhoneValidityStatusUpdate: connectActions.updatePhoneMismatchStatus,
  SetNotification: setNotification,
};

function ContactInformationContainer(props) {
  const {
    candidate,
    connectInfo,
    addContact,
    jobGuid,
    creditsRefunded,
    getRefundForInvalidContacts,
    candidateConnectStatus,
    currentJobDetails,
    mailRef,
    smsRef,
    allSmsRef,
    callRef,
    onSubTabClick,
    setEmailComposeModalVisibility,
    setMessageComposeModalVisibility,
    setCurrentSelectedEmail,
    handleValidityStatusUpdate,
    handlePhoneValidityStatusUpdate,
    setWorkflowComposeModalVisibility,
    candidateWorkflowHistory,
    setWorkflowSelectedEmails,
    usersById,
    featureToggleList,
    SetNotification,
    messageTab,
    candidateContext,
  } = props;
  return (
    <div>
      <AvailableContactInformation
        candidate={candidate}
        connectInfo={connectInfo}
        addContact={addContact}
        jobGuid={jobGuid}
        creditsRefunded={creditsRefunded}
        getRefundForInvalidContacts={getRefundForInvalidContacts}
        candidateConnectStatus={candidateConnectStatus}
        currentJobDetails={currentJobDetails}
        mailRef={mailRef}
        smsRef={smsRef}
        allSmsRef={allSmsRef}
        callRef={callRef}
        onSubTabClick={onSubTabClick}
        setEmailComposeModalVisibility={setEmailComposeModalVisibility}
        setWorkflowComposeModalVisibility={setWorkflowComposeModalVisibility}
        setWorkflowSelectedEmails={setWorkflowSelectedEmails}
        setMessageComposeModalVisibility={setMessageComposeModalVisibility}
        candidateWorkflowHistory={candidateWorkflowHistory}
        setCurrentSelectedEmail={setCurrentSelectedEmail}
        handleValidityStatusUpdate={handleValidityStatusUpdate}
        handlePhoneValidityStatusUpdate={handlePhoneValidityStatusUpdate}
        usersById={usersById}
        featureToggleList={featureToggleList}
        SetNotification={SetNotification}
        messageTab={messageTab}
        candidateContext={candidateContext}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactInformationContainer);
export { ContactInformationContainer as ContactInformationContainerWithoutStore };
