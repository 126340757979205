import React from 'react';
import { Modal, Form, Radio, Select } from 'antd';
import debounce from 'lodash/debounce';
import _ from 'lodash';
import { removeCommaFromEnd } from '../../Utils/TextUtils';
import styles from './AssociateCandidateModal.module.scss';
import { validateCandidateTransfer } from '../../Utils/FormValidators';

const { Option } = Select;

const AssociateCandidateToJobOrContext = props => {
  const {
    form,
    associateCandidateModalStatus,
    toggleAssociateCandidateModalStatus,
    fetchJobs,
    bulkCandidateRecommend,
    bulkSegmentCandidateRecommend,
    changeCandidateStatus,
    version = 'arya',
    candidateListType = 'manualsearch',
  } = props;
  const [jobContext, setJobContext] = React.useState('Job');
  const [options, setOptions] = React.useState([]);
  const [fetchJobsApiStatus, setFetchJobsApiStatus] = React.useState();
  const [candidateTransferApiStatus, setCandidateTransferApiStatus] = React.useState();
  const { visible, modalInfo } = associateCandidateModalStatus;
  const {
    isBulkIconActivated,
    selectedCandidatesCount,
    candidateIds,
    isEnableCustomSelect,
    customSelectNumber,
    clearBulkActions,
    currentSelectedAllCandidates,
    transferType,
    bulkActivitySelection,
  } = modalInfo;
  const addressline = `${modalInfo.Email} - ${modalInfo.Address}`;

  const onChangeJobContext = e => {
    if (jobContext !== e.target.value) {
      setOptions([]);
      form.setFieldsValue({ context: undefined });
      setJobContext(e.target.value);
    }
  };

  const { getFieldDecorator } = form;

  const fetchJobsOrLists = async (searchTerm, _destinationName) => {
    const payload =
      _destinationName === 'Segment'
        ? {
            count: 10,
            skip: 0,
            statusId: 1,
            searchTerm,
            EntityType: 'Segment',
            isAssigned: true,
          }
        : {
            count: 10,
            skip: 0,
            statusId: 1,
            searchTerm,
            isAssigned: true,
          };
    const result = await fetchJobs(payload);
    const updatedResult = result?.Jobs ?? [];
    setOptions(
      (updatedResult ?? []).map(job => ({
        value: job.JobId,
        text: `${job.JobTitle} (Id: ${version !== 'ats' || jobContext === 'Segment' ? job.JobId : job.JobCode}) ${
          jobContext === 'Job' ? '|' : ''
        } ${
          jobContext === 'Segment' || job.Location === undefined || job.Location === ''
            ? ''
            : removeCommaFromEnd(job.Location)
        } ${jobContext === 'Segment' || job.Location === undefined || job.Location === '' ? '' : ','} ${
          jobContext === 'Job' ? job.CountryCode : ''
        } `,
      }))
    );
  };

  const onSearch = debounce(async (value, _destinationName) => {
    if (value.length) {
      setFetchJobsApiStatus('INPROGRESS');
      await fetchJobsOrLists(value, _destinationName);
      setFetchJobsApiStatus('COMPLETED');
    }
  }, 600);

  const dropDownStatus = () => {
    return fetchJobsApiStatus === 'INPROGRESS' ? <p>...Loading</p> : <p>{jobContext} not found </p>;
  };
  const notFoundContent = dropDownStatus();

  const handleTransfer = async () => {
    const actionName = 'Shortlisted';
    form.validateFields(async (err, values) => {
      if (!err) {
        setCandidateTransferApiStatus('INPROGRESS');
        if (transferType === 'bulk') {
          const recommendedInfoUpdateCommand = currentSelectedAllCandidates.map(candidate => {
            let _candidate;
            if (candidateListType === 'aryarecommended') _candidate = { CandidateId: candidate };
            else _candidate = _.cloneDeep(candidate);
            _.set(_candidate, ['RecommendedInfo', 'Status'], actionName);
            return _candidate;
          });
          await bulkCandidateRecommend({
            jobId: values.context,
            recommendedInfoUpdateCommand,
            candidateListType,
            bulkActivitySelection,
            actionName,
            candidateContext: jobContext.toLowerCase(),
          });
        } else {
          const postData = {
            candidateId: modalInfo.candidateIds[0],
            metadata: modalInfo,
            status: actionName,
            jobId: values.context,
            mustHaves: [],
          };
          changeCandidateStatus(postData, null, true, jobContext.toLowerCase());
        }
        setCandidateTransferApiStatus('COMPLETED');
        toggleAssociateCandidateModalStatus(false, {});
        if (clearBulkActions) {
          clearBulkActions();
        }
        form.setFieldsValue({ context: undefined });
      }
    });
  };

  return (
    <div className={styles.associateCandidateContainer}>
      <Modal
        zIndex={2100}
        width={420}
        visible={visible}
        okButtonProps={{ shape: 'round', loading: candidateTransferApiStatus === 'INPROGRESS' }}
        cancelButtonProps={{ shape: 'round' }}
        onCancel={() => {
          toggleAssociateCandidateModalStatus(false, {});
        }}
        okText="Add"
        onOk={handleTransfer}
        title="Add to"
      >
        <div>
          <div className={styles.candidateDetail}>
            {isBulkIconActivated ? (
              <h2>{selectedCandidatesCount} Candidates Selected</h2>
            ) : (
              <>
                <h2>{modalInfo.Name}</h2>
                <span>{addressline}</span>
              </>
            )}
          </div>
        </div>
        <div>
          <Form.Item label="Select destination" className={styles.formItem} required>
            <Radio.Group onChange={onChangeJobContext} defaultValue="Job">
              <Radio.Button shape="round" value="Job" className={styles.contextButton}>
                Job
              </Radio.Button>
              <Radio.Button shape="round" value="Segment" className={styles.contextButton}>
                Segment
              </Radio.Button>
            </Radio.Group>
            <div>
              {getFieldDecorator('context', {
                rules: [
                  { required: true, message: 'Please add destination' },
                  {
                    validator: (rule, value, callback) =>
                      validateCandidateTransfer(
                        value,
                        callback,
                        candidateIds,
                        isEnableCustomSelect,
                        customSelectNumber,
                        'Please select a candidate'
                      ),
                  },
                ],
              })(
                <Select
                  allowClear
                  showSearch
                  filterOption={false}
                  showArrow={false}
                  notFoundContent={notFoundContent}
                  onSearch={value => onSearch(value, jobContext)}
                  getPopupContainer={node => node.parentNode}
                  placeholder="Search"
                  className={styles.contextSearch}
                >
                  {options?.map(option => (
                    <Option key={option.value} value={option.value}>
                      {option.text}
                    </Option>
                  ))}
                </Select>
              )}
            </div>
          </Form.Item>
        </div>
      </Modal>
    </div>
  );
};

export default Form.create()(AssociateCandidateToJobOrContext);
