import React, { useState } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Form } from 'antd';
import * as quickSearchFormUtils from '../../../Components/QuickSearchForm/QuickSearchFormUtils';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import { getConfig, getPortalsConfig, getWhiteLabelInformation } from '../../../Reducers/ConfigReducer';
import { getJobUtilities } from '../../../Reducers/JobReducer';
import styles from './ManualSearchSources.module.scss';
import { getSourceName } from '../../../Utils/SourceUtils';
import ManualSearchSourceItem from './ManualSearchSourceItem';
import SourceItemWrapper from '../../../CompoundComponents/Sources/SourceItemWrapper';
import message from '../ManualSearchMessages';

const { Item } = Form;

const mapStateToProps = state => ({
  userConfig: getConfig(state),
  portalsConfig: getPortalsConfig(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
  featureToggleList: getFeatureToggleList(state),
  jobUtilities: getJobUtilities(state),
});

const mapDispatchToProps = {};

function ManualSearchSources(props) {
  const [advanceSettingsVisiblePopover, setAdvanceSettingsVisiblePopover] = useState();

  const {
    form,
    userConfig,
    portalsConfig,
    whiteLabelInfo,
    featureToggleList,
    jobUtilities,
    sources,
    advancedSettings,
    setAdvancedSettings,
    sourceNamesSelected,
    setSelectedSourceNames,
    defaultAdvancedSettings,
    currentJobDetails,
    candidateContext,
    isNonInternalPortalForSegmentEnabled,
  } = props;
  const applyAdvancedSettings = _advancedSettings => {
    setAdvancedSettings(_advancedSettings);
  };
  const onSourceSelect = sourceName => {
    let _sourceNamesSelected = [...sourceNamesSelected];
    const index = _sourceNamesSelected.findIndex(sourceSelected => sourceName === sourceSelected);
    if (index !== -1) {
      _sourceNamesSelected.splice(index, 1);
    } else {
      _sourceNamesSelected = sourceNamesSelected.concat(sourceName);
    }
    const initialSourceNames = sources.map(x => x.Portal);
    _sourceNamesSelected = _sourceNamesSelected.sort(
      (x, y) => initialSourceNames.indexOf(x) - initialSourceNames.indexOf(y)
    );
    setSelectedSourceNames(_sourceNamesSelected);
  };

  const handleAdvanceSettingsPopoverVisibility = (visible, popoverName, isAdvanceSettingsDisabled) => {
    if (!isAdvanceSettingsDisabled && visible) {
      setAdvanceSettingsVisiblePopover(popoverName);
    } else {
      setAdvanceSettingsVisiblePopover();
    }
  };

  const getSourceCredentials = source => {
    if (source.Group && source.Portal && source.Name) {
      return { SearchType: source.Name };
    }
    return portalsConfig?.[source.Portal]?.PortalCredentials?.find(
      credential => credential.CountryCode === currentJobDetails.CountryCode || credential.CountryCode === 'All'
    );
  };

  const setAdvanceSettingsPopover = sourceName => {
    setAdvanceSettingsVisiblePopover(sourceName);
  };

  const onApplyAdvancedSettings = (source, values) => {
    const clonedAdvancedSettings = _.cloneDeep(advancedSettings) || {};
    const sourceName = getSourceName(source);
    clonedAdvancedSettings[sourceName] = values;
    applyAdvancedSettings(clonedAdvancedSettings);
  };

  const sourcesConfig = userConfig?.Sources;

  const _sources = sources.map(source => {
    const newSource = _.cloneDeep(source);
    const sourceConfig = sourcesConfig?.find(x => x.Source.Portal === source.Portal);
    newSource.SearchType = sourceConfig?.Source?.Name;
    return newSource;
  });

  const sourceWrapperProps = {
    form,
    defaultAdvancedSettings,
    userConfig,
    sourceNamesSelected,
    advanceSettingsVisiblePopover,
    whiteLabelInfo,
    defaultValues: currentJobDetails,
    sourcesConfig: userConfig?.Sources,
    portalsConfig,
    countries: jobUtilities?.countries ?? [],
    sources: _sources,
    revealActiveChannelSourceName: featureToggleList.RevealPortalsUnderGroup.IsEnabled,
    isAlternateLocationEnabled: featureToggleList.AlternateLocation.IsEnabled,
    isStateWideSearchEnabled: featureToggleList.StateWideSearch.IsEnabled,
    setAdvanceSettingsPopover,
    handleAdvanceSettingsPopoverVisibility,
    onSourceSelect,
    onApplyAdvancedSettings,
    isSocialAdvanceSettingsEnabled: featureToggleList.SocialAdvanceSettings.IsEnabled,
  };
  const {
    MaskActiveAsInternal: { IsEnabled: isMaskActiveAsInternalEnabled },
  } = featureToggleList;
  const allowedSources = quickSearchFormUtils.getAllowedSources(_sources, true);
  const configuredSources =
    candidateContext === 'candidateCreation'
      ? allowedSources.filter(source => source.Portal === 'Internal')
      : allowedSources;
  return (
    <div className={styles.sourcesWrapper}>
      <div className={styles.sourcesText}>
        <FormattedMessage {...message.sourceHeader} />
      </div>
      <Item>
        <div className={styles.manualSearchSources}>
          {configuredSources.map(source => {
            const sourceName = getSourceName(source);
            return (
              <SourceItemWrapper {...sourceWrapperProps} source={source} key={sourceName}>
                <ManualSearchSourceItem
                  sourcesSectionVisibility
                  source={source}
                  candidateContext={candidateContext}
                  isNonInternalPortalForSegmentEnabled={isNonInternalPortalForSegmentEnabled}
                  isSocialAdvanceSettingsEnabled={featureToggleList.SocialAdvanceSettings.IsEnabled}
                  sourceName={sourceName}
                  sourceNamesSelected={sourceNamesSelected}
                  isMaskActiveAsInternalEnabled={isMaskActiveAsInternalEnabled}
                />
              </SourceItemWrapper>
            );
          })}
        </div>
      </Item>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualSearchSources);
export { ManualSearchSources as ManualSearchSourcesWithoutStore };
