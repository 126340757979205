export interface IFeature {
  Name: string;
  IsEnabled: boolean;
  IsAllowed: boolean;
}

export interface ICountryFeature extends IFeature {
  AllowedCountryCodes: Array<string>;
  IsPublic: boolean;
}

interface IFeatureToogleList {
  ApplicationSettings: IFeature;
  AudioRead: IFeature;
  AudioWrite: IFeature;
  AryaRejection: IFeature;
  AoDAdvertisement: IFeature;
  BotRead: IFeature;
  MinutesDelayInWorkflow: IFeature;
  Workflow: IFeature;
  IsCareerportalChatViewEnabled: IFeature;
  BotWrite: IFeature;
  BulkRecommend: IFeature;
  Calendar: IFeature;
  CandidateFilter: IFeature;
  CandidatePipeline: IFeature;
  CandidateSearch: IFeature;
  CandidateSort: IFeature;
  Connect: IFeature;
  ContactFetch: IFeature;
  BulkContactFetch: IFeature;
  Diversity: ICountryFeature;
  EmailRead: IFeature;
  EmailWrite: IFeature;
  EmailReply: IFeature;
  EngagedBucket: IFeature;
  FlagConversation: IFeature;
  JobConfiguration: IFeature;
  JobDistribution: IFeature;
  JobIntel: IFeature;
  JobSettings: IFeature;
  JobStatus: IFeature;
  JobTag: IFeature;
  OptimizedJobSearch: IFeature;
  MessageConsentTemplateCreation: IFeature;
  MessageRead: IFeature;
  MessageWrite: IFeature;
  Notes: IFeature;
  NotesIndicator: IFeature;
  OrderProgress: IFeature;
  PaidJobService: IFeature;
  PendingOrders: IFeature;
  Personality: IFeature;
  PublishingCandidates: IFeature;
  AdvanceSearchBulkRecommend: IFeature;
  AdvanceSearchConnectOptions: IFeature;
  ShareJob: IFeature;
  ShortlistReject: IFeature;
  SourceFilter: IFeature;
  SourcingNotificationEmails: IFeature;
  SourcingProgress: IFeature;
  WhiteGloveServiceProgress: IFeature;
  RevealPortalsUnderGroup: IFeature;
  AlternateLocation: IFeature;
  JobList: IFeature;
  Notification: IFeature;
  Profile: IFeature;
  ConnectV2: IFeature;
  CustomizeJobUrl: IFeature;
  CustomizeJobNotInterestedUrl: IFeature;
  ReactEmailEditor: IFeature;
  ReportingPortal: IFeature;
  PrimaryFromEmail: IFeature;
  SalesDashboardReports: IFeature;
  CareerProjectionReport: IFeature;
  CandidateResumeGeneration: ICountryFeature;
  AryaJobPostingUrl: IFeature;
  MarketIntelligence: IFeature;
  SourceLimitConfiguration: IFeature;
  AdvancedJobCreation: IFeature;
  AdvanceSearchBulkConnect: IFeature;
  ShareJobOnSocialMedia: IFeature;
  MultiLocationSourcing: IFeature;
  StateWideSearch: IFeature;
  AddEmailAndVerify: IFeature;
  PulsePromotionalOffer: IFeature;
  CandidateManualSearch: IFeature;
  JobDescriptionTemplates: IFeature;
  FirstJobFree: IFeature;
  CandidateCRM: IFeature;
  ViewApplicationOnCandidateCard: IFeature;
  UndoCandidateApplication: IFeature;
  SegmentCandidateSearchLimit: IFeature;
  CandidateBulkSelection: IFeature;
  CandidateBulkSelectionInput: IFeature;
  JobSummary: IFeature;
  AllowNonInternalPortalsForSegment: IFeature;
  CandidateMatchingJobs: IFeature;
  GenerativeAIEmailGeneration: IFeature;
  CandidateIntelV2: IFeature;
  KnowledgeBase: IFeature;
  ConnectedCandidatesV2: IFeature;
  BulkSms: IFeature;
  SocialAdvanceSettings: IFeature;
  CandidateIntelDownload: IFeature;
  AdvanceSearchV2: IFeature;
  AryaRanking: IFeature;
  ScoutingAgent: IFeature;
  CandidateLevelWorkflow: IFeature;
  PrefetchAdvanceSearchResults: IFeature;
  AryaAutoSourcing: IFeature;
  ShowAllClientCandidateStatus: IFeature;
  SMSFiltersForJob: IFeature;
  UnsubscribeCandidate: IFeature;
  ContactValidityStatusUpdate: IFeature;
  JobIdealCandidateResumeUpload: IFeature;
  AssistedSourcing: IFeature;
  AssistedCandidatePublish: IFeature;
  RecruiterAI: IFeature;
  AdvancedSearchAggregationFilters: IFeature;
  AodSubmitCandidate: IFeature;
  AssistedCandidateIndicator: IFeature;
  MoveToAppliedInJobs: IFeature;
  ShowAdditionalInfoInJobInfo: IFeature;
  SegmentCreation: IFeature;
  SubSegmentCreation: IFeature;
  JobLevelSourcingSupportAssistant: IFeature;
  SubSegmentEmailAlerts: IFeature;
  MessageDeliveryStatus: IFeature;
  PreferredKeywordAutoSelect: IFeature;
  AdvanceSearchAliasSkills: IFeature;
  ChatBotDemo: IFeature;
  AryaAutoSourcingForRepetitiveJobs: IFeature;
  RemoveEngageToggle: IFeature;
  SmartAgentCandidate: IFeature;

  AllConversations: IFeature;
  JobUsageBudget: IFeature;
  CandidateCopy: IFeature;
  DefaultAryaEmailProvider: IFeature;
  MaskActiveAsInternal: IFeature;
  CandidateCopyForJob: IFeature;
  ATSAuthentication: IFeature;
  ScoreAndRankAutomation: IFeature;
  CandidateCreation:IFeature;
}

export interface IUiFeatureToggleList extends IFeatureToogleList {
  ConnectWidget: IFeature;
}

function featureToggleMapper(features: IFeatureToogleList): IUiFeatureToggleList {
  const IsConnectWidgetEnabled =
    (features.ContactFetch.IsEnabled ||
      features.AudioRead.IsEnabled ||
      features.EmailRead.IsEnabled ||
      features.MessageRead.IsEnabled) &&
    !features.PaidJobService.IsEnabled;
  const connectWidgetFeature: IFeature = {
    Name: 'ConnectWidget',
    IsEnabled: IsConnectWidgetEnabled,
    IsAllowed: IsConnectWidgetEnabled,
  };
  return { ...features, ConnectWidget: connectWidgetFeature };
}

function getIsFeaturesEnabled(allFeatures: IFeatureToogleList, featuresToGet: string[]): boolean[] {
  return featuresToGet.map(feature => (allFeatures[feature] ? allFeatures[feature].IsEnabled : false));
}
function getIsFeaturesAllowed(allFeatures: IFeatureToogleList, featuresToGet: string[]): boolean[] {
  return featuresToGet.map(feature => (allFeatures[feature] ? allFeatures[feature].IsAllowed : false));
}

export { featureToggleMapper, getIsFeaturesAllowed, getIsFeaturesEnabled };
