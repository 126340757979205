import { Button, Icon, Dropdown, Menu, message, Tooltip, Form } from 'antd';
import React from 'react';
import classNames from 'classnames';
import queryString from 'query-string';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect, batch } from 'react-redux';
import * as candidateActions from '../../../Actions/CandidateActions';
import * as manualSearchActions from '../../../Actions/ManualSearchActions';
import * as manualSearchCandidateActions from '../../../Actions/ManualSearchCandidateActions';
import * as jobCandidatesTabActions from '../../../Actions/JobCandidatesTabActions';
import { getPageSize, getActiveTab } from '../../../Reducers/JobCandidatesTabReducer';
import {
  generateManualSearchPayload,
  generateManualSearchPayloadWithoutPersonalInfo,
  getDefaultFormValuesFromCriteria,
  getManualSearchCandidatesCountPerBatch,
} from '../../../Utils/ManualSearchUtils';
import { getConfig, getPortalsConfig, getConfiguredSources } from '../../../Reducers/ConfigReducer';
import { getJobDetails } from '../../../Reducers/JobReducer';
import { getSourceName } from '../../../Utils/SourceUtils';
import styles from './ManualSearchFormFooter.module.scss';
import { getImpersonatedUserDetails, getCurrentUserDetails } from '../../../Reducers/UserSessionReducer';
import eventTypes from '../../../Analytics/EventTypes';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import * as scoutingAgentActions from '../../../Actions/ScoutingAgentActions';
import SearchSavedCriteriaModal from '../HeaderOptions/Modals/SearchSavedCriteriaModal/SearchSavedCriteriaModal';
import SaveSearchCriteriaModal from '../HeaderOptions/Modals/SaveSearchCriteriaModal/SaveSearchCriteriaModal';
import {
  getManualSearchSelectedCriteriaType,
  getIsReactivationAllowed,
  getManualSearchCriteriaInfo,
  getManualSearchCriteriaType,
} from '../../../Reducers/ManualSearchReducer';
import { getScoutingAgentConfig } from '../../../Reducers/ScoutingAgentReducer';
import messages from '../ManualSearchMessages';
import { setNotification } from '../../../Actions/ActionCreators/ConnectActions';

const mapStateToProps = (state, props) => ({
  pageSize: getPageSize(state),
  userConfig: getConfig(state),
  impersonatedUser: getImpersonatedUserDetails(state),
  currentJobDetails: getJobDetails(state, props.jobId),
  portalsConfig: getPortalsConfig(state),
  sources: getConfiguredSources(state),
  featureToggleList: getFeatureToggleList(state),
  allTabCandidatesFetchApi: getApiStatus(state, 'allTabCandidatesFetchApi'),
  activeTab: getActiveTab(state),
  selectedCriteriaType: getManualSearchSelectedCriteriaType(state, { jobId: props.jobId }),
  isReactivationAllowed: getIsReactivationAllowed(state, { jobId: props.jobId }),
  scoutingAgentConfig: getScoutingAgentConfig(state),
  manualSearchCriteriaInfo: getManualSearchCriteriaInfo(state, { jobId: props.jobId }),
  manualSearchCriteriaType: getManualSearchCriteriaType(state),
  currentUserDetails: getCurrentUserDetails(state),
});

const mapDispatchToProps = {
  fetchManualSearchCandidateList: candidateActions.fetchManualSearchCandidateList,
  setManualSearchPayload: manualSearchActions.setManualSearchPayload,
  saveManualSearchCriteria: manualSearchActions.saveManualSearchCriteria,
  updateManualSearchCriteria: manualSearchActions.updateManualSearchCriteria,
  setManualSearchFormErrorNotification: manualSearchActions.setManualSearchFormErrorNotification,
  setManualSearchFormValues: manualSearchActions.setManualSearchFormValues,
  setJobCandidateTabActiveSourceName: jobCandidatesTabActions.setJobCandidateTabActiveSourceName,
  setManualSearchActiveSource: jobCandidatesTabActions.setManualSearchActiveSource,
  fetchManualSearchAllTabCandidates: manualSearchCandidateActions.fetchManualSearchAllTabCandidates,
  setScoutingAgentModalVisibilityStatus: scoutingAgentActions.setScoutingAgentModalVisibility,
  setSelectedCriteriaName: manualSearchActions.setSelectedCriteriaName,
  fetchManualSearchCriteriaById: manualSearchActions.fetchManualSearchCriteriaById,
  setSelectedCriteriaType: manualSearchActions.setSelectedCriteriaType,
  setIsReactivationAllowedFlag: manualSearchActions.setIsReactivationAllowedFlag,
  setSelectedScoutingAgentId: scoutingAgentActions.setSelectedScoutingAgentIdAction,
  setAllTabSelectedCandidateFilters: manualSearchCandidateActions.setAllTabSelectedCandidateFilters,
  clearAllTabFiltersInfo: manualSearchCandidateActions.clearAllTabFiltersInfo,
  setIsAllTabCandidateAggregationAppliedFlag: manualSearchActions.setIsAllTabCandidateAggregationAppliedFlag,
  SetNotification: setNotification,
};

export const handleUpdateCriteriaWithoutModal = async (
  e,
  getCriteriaCallback,
  manualSearchCriteriaInfo,
  updateManualSearchCriteriaCallback,
  setSaveSearchCriteriaFailureModalCallback,
  handleSaveCriteriaSuccessCallback
) => {
  if (e.domEvent) e.domEvent.stopPropagation();
  else e.stopPropagation();
  const criteria = getCriteriaCallback();
  if (!criteria.CriteriaName) criteria.CriteriaName = manualSearchCriteriaInfo?.selectedManualSearchCriteriaName;
  const id = manualSearchCriteriaInfo?.selectedManualSearchCriteriaId;
  await updateManualSearchCriteriaCallback({ criteria, id });
  setSaveSearchCriteriaFailureModalCallback(false);
  handleSaveCriteriaSuccessCallback();
};

function ManualSearchFormFooter(props) {
  const {
    form,
    unsavedCriteriaId,
    setManualSearchPayload,
    saveManualSearchCriteria,
    updateManualSearchCriteria,
    advancedSettings,
    sources,
    sourceNamesSelected,
    initalSources,
    jobId,
    jobGuid,
    onSearchCandidates,
    setManualSearchFormErrorNotification,
    fetchManualSearchAllTabCandidates,
    userConfig,
    setManualSearchFormValues,
    isManualSearchFormMinimized,
    candidateContext,
    isNonInternalPortalForSegmentEnabled,
    seeMoreButton,
    minimizedViewSearchButton,
    setPreFetchRequired,
    isPreFetchRequied,
    allTabCandidatesFetchApi,
    searchContext,
    activeTab,
    setScoutingAgentModalVisibilityStatus,
    isModalVisible,
    setIsModalVisible,
    selectedCriteriaId,
    setSelectedCriteriaId,
    setSelectedCriteriaName,
    fetchManualSearchCriteriaById,
    currentJobDetails,
    setDefaultFormValues,
    setAdvancedSettings,
    setSelectedCriteriaType,
    setIsReactivationAllowedFlag,
    selectedCriteriaType,
    featureToggleList,
    setSelectedScoutingAgentId,
    isReactivationAllowed,
    scoutingAgentConfig,
    manualSearchCriteriaInfo,
    manualSearchCriteriaType,
    setIsAllTabCandidateAggregationAppliedFlag,
    setAllTabSelectedCandidateFilters,
    clearAllTabFiltersInfo,
    currentUserDetails,
    pageName,
    version,
    SetNotification,
    intl,
    impersonatedUser,
  } = props;

  function isNumericString(str) {
    return /^\d+$/.test(str);
  }

  const jobAryaStatus = currentJobDetails?.AryaStatus?.toLowerCase();
  const isAryaJobStatusOpen = jobAryaStatus === 'open';
  const { IsJobActivationChargeEnabledOnJobOpen: isJobActivationChargeEnabledOnJobOpen } = userConfig;
  const isManualSearchButtonDisabled =
    !isAryaJobStatusOpen && candidateContext === 'job' && isJobActivationChargeEnabledOnJobOpen;
  const manualSearchButtonDisabledTooltip = isManualSearchButtonDisabled ? (
    <div>
      <FormattedMessage {...messages.cantPerformAryaSearchLabel} /> {jobAryaStatus}{' '}
      <FormattedMessage {...messages.job} />
    </div>
  ) : (
    ''
  );

  function showScoutingAgentModal(e, mode) {
    if (e.domEvent) e.domEvent.stopPropagation();
    else e.stopPropagation();
    setScoutingAgentModalVisibilityStatus(true, mode);
  }

  const showSaveSearchCriteriaModal = e => {
    form.validateFieldsAndScroll(error => {
      if (error) {
        setManualSearchFormErrorNotification({ errorFields: Object.keys(error) }, intl);
        return;
      }
      setIsSaveSearchCriteriaModalVisible(true);
    });
  };

  const [isSaveSearchCriteriaModalVisible, setIsSaveSearchCriteriaModalVisible] = React.useState(false);
  const [criteriaName, setCriteriaName] = React.useState();
  const [criteriaId, setCriteriaId] = React.useState();
  const [isSaveSearchCriteriaFailureModalVisible, setSaveSearchCriteriaFailureModalVisible] = React.useState(false);

  const [saveButtonData, setSaveButtonData] = React.useState([
    { text: 'Save criteria', onClick: showSaveSearchCriteriaModal },
    { text: 'Create scouting agent', onClick: e => showScoutingAgentModal(e, 'new') },
  ]);

  React.useEffect(() => {
    if (manualSearchCriteriaType === 'CreateScoutingAgent') {
      setSaveButtonData([
        { text: 'Create scouting agent', onClick: e => showScoutingAgentModal(e, 'new') },
        { text: 'Save criteria', onClick: showSaveSearchCriteriaModal },
      ]);
    }
  }, [manualSearchCriteriaType]);

  const isPrefetchAdvanceSearchResultsEnabled = featureToggleList?.PrefetchAdvanceSearchResults?.IsEnabled;
  const setDraftCriteria = (criteria, context) => {
    if (unsavedCriteriaId) {
      const criteriaWithoutPersonalInfo = generateManualSearchPayloadWithoutPersonalInfo({ criteria });
      updateManualSearchCriteria({
        criteria: criteriaWithoutPersonalInfo,
        id: unsavedCriteriaId,
        pageName,
        version,
        searchContext: context,
        currentUserDetails,
      });
    } else saveManualSearchCriteria({ criteria, pageName, version, searchContext: context, currentUserDetails });
  };

  const getIsCriteriaUpdationAllowedOnImpersonation = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const allowCriteriaUpdate = urlParams.get('allowcriteriaupdate');
    return allowCriteriaUpdate === 'true';
  };

  const onSearch = context => {
    if (isManualSearchButtonDisabled) return;
    form.validateFieldsAndScroll(async (error, values) => {
      if (error) {
        setManualSearchFormErrorNotification({ errorFields: Object.keys(error) }, intl);
        return;
      }
      clearAllTabFiltersInfo({ jobId });
      setIsAllTabCandidateAggregationAppliedFlag({ jobId, isFilterAppliedFlag: false });

      onSearchCandidates(context);
      const selectedSources = sourceNamesSelected.map(x => sources.find(y => getSourceName(y) === x)).filter(x => !!x);
      const manualSearchPayload = generateManualSearchPayload({
        formValues: values,
        locationType: values.LocationType,
        activeSearchString: values.ActiveSearchString,
        advancedSettings,
        id: unsavedCriteriaId,
        isAdvancedSearchV2Enabled: true,
      });
      const filteredManualSearchPayload = {
        ...manualSearchPayload,
        Titles: manualSearchPayload.Titles.filter(x => x.IsPreferred),
        Skills: {
          ...manualSearchPayload.Skills,
          Prefers: manualSearchPayload.Skills.Prefers?.filter(x => x.IsPreferred),
        },
      };
      const sourcesPayload = selectedSources.map(x => ({
        ...x,
        Name: x?.Portal === 'Internal' && !userConfig.ShowVaultName ? undefined : x.Name,
      }));
      const manualSearchCandidatesCountPerBatch = getManualSearchCandidatesCountPerBatch(
        featureToggleList.AdvancedSearchAggregationFilters.IsEnabled,
        candidateContext
      );
      const manualSearchCandidatesFetchPayload = {
        ...filteredManualSearchPayload,
        JobId: jobId,
        Sources: sourcesPayload,
        From: 0,
        Size: filteredManualSearchPayload.IsExactTitleMatchRequired ? 10 : manualSearchCandidatesCountPerBatch,
      };
      const allowReordering =
        candidateContext === 'job' || (candidateContext === 'segment' && isNonInternalPortalForSegmentEnabled);
      await batch(async () => {
        if (!impersonatedUser || getIsCriteriaUpdationAllowedOnImpersonation()) {
          const criteria = {
            JobId: jobId,
            IsDraft: true,
            Criteria: { ...manualSearchPayload, Sources: selectedSources },
          };
          if (candidateContext !== 'candidateCreation') setDraftCriteria(criteria, context);
        }
        const newValues = {
          ...values,
          sourceNamesSelected: values?.sourceNamesSelected || initalSources,
        };
        setManualSearchFormValues({
          manualSearchFormValues: newValues,
          jobId,
        });
        setManualSearchPayload({
          manualSearchPayload: {
            ...filteredManualSearchPayload,
            Sources: [{ Portal: 'All', Type: 'Primary' }].concat(sourcesPayload),
          },
          jobId,
          isAdvancedSearchV2Enabled: true,
        });

        await fetchManualSearchAllTabCandidates({
          manualCriteria: manualSearchCandidatesFetchPayload,
          batchesFetched: 1,
          jobId,
          jobGuid,
          allowReordering,
          shouldSetInitialCandidatesCountBySource: true,
          showAggregationFilterLoader: false,
          candidateContext,
        });
      });

      if (featureToggleList.AdvancedSearchAggregationFilters.IsEnabled && candidateContext === 'job') {
        fetchManualSearchAllTabCandidates({
          manualCriteria: {
            ...manualSearchCandidatesFetchPayload,
            From: manualSearchCandidatesFetchPayload.Size,
            Size: manualSearchCandidatesCountPerBatch * 3,
          },
          batchesFetched: 4,
          jobId,
          jobGuid,
          allowSetFetchCandidateApiStatus: false,
          isMore: true,
          isBackgroundCandidatesFetchCall: true,
        });
      }
    });
  };

  const triggerAutoFetch = async () => {
    setTimeout(() => {
      onSearch(searchContext);
    }, 0);
    setPreFetchRequired({ jobId, isPrefetchRequiredFlag: false });
  };

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const activeUrlTab = urlParams.get('activeTab');
    const source = urlParams.get('source');
    if (
      candidateContext !== 'segment' &&
      isPreFetchRequied &&
      searchContext === 'default' &&
      (activeTab === 'sourced' || !activeTab) &&
      (isPrefetchAdvanceSearchResultsEnabled || source === 'aiExperience') &&
      activeUrlTab !== 'scoutingAgent'
    ) {
      triggerAutoFetch();
    }
  }, [activeTab]);

  React.useEffect(() => {
    if (!minimizedViewSearchButton) {
      const buttonAndMenuOption = [
        manualSearchCriteriaType === 'CreateScoutingAgent'
          ? [
              {
                text: <FormattedMessage {...messages.createScoutingAgentLabel} />,
                onClick: e => showScoutingAgentModal(e, 'new'),
              },
              { text: <FormattedMessage {...messages.saveCriteriaButton} />, onClick: showSaveSearchCriteriaModal },
            ]
          : [
              { text: <FormattedMessage {...messages.saveCriteriaButton} />, onClick: showSaveSearchCriteriaModal },
              {
                text: <FormattedMessage {...messages.createScoutingAgentLabel} />,
                onClick: e => showScoutingAgentModal(e, 'new'),
              },
            ],
        [
          { text: <FormattedMessage {...messages.saveAsNewCriteriaLabel} />, onClick: showSaveSearchCriteriaModal },
          {
            text: <FormattedMessage {...messages.updateCriteriaLabel} />,
            onClick: e =>
              handleUpdateCriteriaWithoutModal(
                e,
                getCriteria,
                manualSearchCriteriaInfo,
                updateManualSearchCriteria,
                setSaveSearchCriteriaFailureModalVisible,
                handleSaveCriteriaSuccess
              ),
          },
          {
            text: <FormattedMessage {...messages.createScoutingAgentLabel} />,
            onClick: e => showScoutingAgentModal(e, 'new'),
          },
        ],
        [
          {
            text: <FormattedMessage {...messages.saveAsNewScoutingAgent} />,
            onClick: e => showScoutingAgentModal(e, 'new'),
          },
          {
            text: <FormattedMessage {...messages.updateScoutingAgentLabel} />,
            onClick: e => showScoutingAgentModal(e, 'update'),
          },
        ],
      ];
      let initialOptions;
      if (!selectedCriteriaType) [initialOptions] = buttonAndMenuOption;
      else if (selectedCriteriaType === 'Criteria') [, initialOptions] = buttonAndMenuOption;
      else [, , initialOptions] = buttonAndMenuOption;
      setSaveButtonData(initialOptions);
    }
  }, [selectedCriteriaType, minimizedViewSearchButton, manualSearchCriteriaType]);

  const getCriteria = () => {
    const payload = generateManualSearchPayload({
      formValues: form.getFieldsValue(),
      locationType: form.getFieldValue('LocationType'),
      activeSearchString: form.getFieldValue('ActiveSearchString'),
      advancedSettings,
      isAdvancedSearchV2Enabled: true,
    });
    return {
      JobId: jobId,
      CriteriaName: criteriaName,
      Criteria: { ...payload, Locations: [], Experience: null, Sources: [], Name: null, Email: null, Mobile: null },
    };
  };

  const handleSaveCriteriaSuccess = () => {
    setSelectedCriteriaName({ jobId, criteriaName });
    SetNotification('SUCCESS', {
      messageId: 'searchCriteriaSavedLabel',
    });
  };

  const handleUpdate = async e => {
    if (e.domEvent) e.domEvent.stopPropagation();
    else e.stopPropagation();
    const criteria = getCriteria();
    await updateManualSearchCriteria({ criteria, id: criteriaId });
    setSaveSearchCriteriaFailureModalVisible(false);
    handleSaveCriteriaSuccess();
  };

  const handleCriteriaModalsVisibility = response => {
    const { isSuccess, code, status, id } = response;
    if (isSuccess && isSaveSearchCriteriaModalVisible) {
      setIsSaveSearchCriteriaModalVisible(false);
      handleSaveCriteriaSuccess();
    } else if (!isSuccess && code === 'CONFLICTING_CANDIDATESEARCHCRITERIA' && status === 409) {
      setIsSaveSearchCriteriaModalVisible(false);
      setSaveSearchCriteriaFailureModalVisible(true);
      setCriteriaId(id);
    }
  };

  const handleSaveCriteria = async isPublic => {
    const criteria = getCriteria();
    criteria.IsPublic = isPublic;
    const response = await saveManualSearchCriteria({ criteria });
    handleCriteriaModalsVisibility(response);
  };
  const handleOk = () => {
    setIsModalVisible(false);
    onApplyCriteria();
  };
  const onApplyCriteria = async () => {
    try {
      const criteria = await fetchManualSearchCriteriaById({ id: selectedCriteriaId });
      const selectedCriteriaTypeLocal = !isNumericString(selectedCriteriaId) ? 'Agent' : 'Criteria';
      if (!isNumericString(selectedCriteriaId)) setSelectedScoutingAgentId(selectedCriteriaId);
      const defaultFormValues = await getDefaultFormValuesFromCriteria({ criteria, currentJobDetails });
      setDefaultFormValues(defaultFormValues);
      setAdvancedSettings({});
      form.resetFields();
      SetNotification('SUCCESS', {
        messageId: 'criteriaUpdatedLabel',
      });
      setSelectedCriteriaType({ jobId, selectedCriteriaType: selectedCriteriaTypeLocal });
      setSelectedCriteriaName({ jobId, criteriaName: criteria.CriteriaName });
      setIsReactivationAllowedFlag({ jobId, isReactivationAllowedFlag: false });
    } catch {
      SetNotification('ERROR', {
        messageId: 'updatingCriteriaErrorLabel',
      });
    }
    setSelectedCriteriaId(undefined);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedCriteriaId(undefined);
  };

  const menu = () => {
    const options = saveButtonData.slice(1, saveButtonData.length);
    return (
      <Menu>
        {options.map(option => (
          <Menu.Item
            key={option.text}
            onClick={e => {
              e.domEvent.stopPropagation();
              option.onClick(e);
            }}
          >
            {option.text}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <div
      className={`${!minimizedViewSearchButton ? styles.manualSearchFormFooter : ''} ${
        isManualSearchFormMinimized ? styles.hideFooter : ''
      }`}
    >
      {minimizedViewSearchButton ? null : seeMoreButton}
      <div
        className={` ${
          minimizedViewSearchButton ? styles.minimizedSearchButton : styles.manualSearchFormFooterButtons
        }}`}
      >
        {!minimizedViewSearchButton ? (
          <SearchSavedCriteriaModal isModalVisible={isModalVisible} handleCancel={handleCancel} handleOk={handleOk} />
        ) : null}

        {!minimizedViewSearchButton ? (
          <SaveSearchCriteriaModal
            isSaveSearchCriteriaModalVisible={isSaveSearchCriteriaModalVisible}
            handleSaveCriteria={handleSaveCriteria}
            setIsSaveSearchCriteriaModalVisible={setIsSaveSearchCriteriaModalVisible}
            setCriteriaName={setCriteriaName}
            isSaveSearchCriteriaFailureModalVisible={isSaveSearchCriteriaFailureModalVisible}
            setSaveSearchCriteriaFailureModalVisible={setSaveSearchCriteriaFailureModalVisible}
            criteriaName={criteriaName}
            handleUpdate={handleUpdate}
          />
        ) : null}
        <div className={styles.manualSearchButtonAndSaveScoutAgent}>
          {!minimizedViewSearchButton && candidateContext !== 'candidateCreation' ? (
            <div
              className={classNames({
                [styles.manualSearchFormSaveButton]: featureToggleList.ScoutingAgent.IsEnabled && scoutingAgentConfig,
              })}
            >
              {featureToggleList.ScoutingAgent.IsEnabled && scoutingAgentConfig ? (
                <Tooltip
                  title={isReactivationAllowed ? '' : <FormattedMessage {...messages.saveTheCriteriaToolTipLabel} />}
                >
                  <Button
                    size="large"
                    shape="round"
                    type="secondary"
                    disabled={!isReactivationAllowed}
                    onClick={saveButtonData[0].onClick}
                    sk-event-name={eventTypes.job.manualSearch.applyManualSearchCriteria}
                  >
                    {saveButtonData[0].text}
                    <Dropdown
                      overlay={menu}
                      trigger={['click']}
                      overlayClassName={styles.saveDropDown}
                      overlayStyle={{ minWidth: '250px' }}
                      placement="bottomRight"
                      style={{ cursor: 'not-allowed' }}
                      disabled={isManualSearchButtonDisabled}
                    >
                      <Tooltip
                        title={
                          isManualSearchButtonDisabled ? (
                            <div>
                              <FormattedMessage {...messages.cantCreateScoutingAgentLabel} /> {jobAryaStatus}{' '}
                              <FormattedMessage {...messages.job} />
                            </div>
                          ) : (
                            ''
                          )
                        }
                      >
                        <div
                          className={styles.iconStyle}
                          style={
                            isManualSearchButtonDisabled
                              ? {
                                  cursor: 'not-allowed',
                                  backgroundColor: 'rgb(245, 245, 245)',
                                  borderTopRightRadius: '20px',
                                  borderBottomRightRadius: '20px',
                                }
                              : {}
                          }
                          role="presentation"
                          onClick={e => e.stopPropagation()}
                        >
                          <Icon type="down" />
                        </div>
                      </Tooltip>
                    </Dropdown>
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  style={{ marginRight: '12px' }}
                  size="large"
                  shape="round"
                  type="secondary"
                  disabled={!isReactivationAllowed}
                  onClick={showSaveSearchCriteriaModal}
                >
                  <FormattedMessage {...messages.saveCriteriaButton} />
                </Button>
              )}
            </div>
          ) : null}
          <Tooltip title={manualSearchButtonDisabledTooltip}>
            <Button
              size="large"
              shape="round"
              type="primary"
              onClick={onSearch}
              sk-event-name={eventTypes.job.manualSearch.applyManualSearchCriteria}
              loading={allTabCandidatesFetchApi === 'INPROGRESS'}
              disabled={isManualSearchButtonDisabled}
            >
              <FormattedMessage {...messages.searchButton} />
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(ManualSearchFormFooter)));
export { ManualSearchFormFooter as ManualSearchFormFooterWithoutStore };
