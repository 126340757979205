import * as actionCreators from './ActionCreators/AddCandidateActionCreator';

function toggleAddCandidateDrawerVisibleStatus(visible) {
  return actionCreators.toggleAddCandidateDrawer(visible);
}
function toggleAssociateCandidateModalStatus(visible, modalInfo) {
  return actionCreators.toggleAssociateCandidateModal(visible, modalInfo);
}

export { toggleAddCandidateDrawerVisibleStatus, toggleAssociateCandidateModalStatus };
