import cleanSet from 'clean-set';
import {
  TOGGLE_ADD_CANDIDATE_VISIBLE_STATUS,
  TOGGLE_ASSOCIATE_CANDIDATE_MODAL,
} from '../Actions/ActionCreators/AddCandidateActionCreator';

const initialState = {
  AddCandidateDrawerVisibleStatus: false,
  AssociateCandidateModal: {
    visible: false,
    modalInfo: {},
  },
};

function AddCandidateReducer(state = initialState, action) {
  let newState;
  if (action.type === TOGGLE_ADD_CANDIDATE_VISIBLE_STATUS) {
    const { visible } = action.payload;
    newState = cleanSet(state, 'AddCandidateDrawerVisibleStatus', visible);
    return newState;
  }
  if (action.type === TOGGLE_ASSOCIATE_CANDIDATE_MODAL) {
    const { visible, modalInfo } = action.payload;
    newState = cleanSet(state, 'AssociateCandidateModal', { visible, modalInfo });
    return newState;
  }

  return state;
}

function getAddCandidateDrawerVisibleStatus(state) {
  return state.AddCandidateReducer.AddCandidateDrawerVisibleStatus;
}
function getAssociateCandidateModalStatus(state) {
  return state.AddCandidateReducer.AssociateCandidateModal;
}

export { AddCandidateReducer, getAddCandidateDrawerVisibleStatus, getAssociateCandidateModalStatus };
