import React from 'react';
import CandidateTransferIcon from '../../Icons/CandidateTransferIconV2';
import styles from './AssociateCandidateModal.module.scss';

const CandidateAssociate = props => {
  const {
    isBulkIconActivated,
    selectedCandidatesCount,
    toggleAssociateCandidateModalStatus,
    candidate,
    isEnableCustomSelect,
    customSelectNumber,
    filterContextId,
    isAryaRecommendedSelectAllChecked,
    candidateIds,
    clearBulkActions,
    currentSelectedAllCandidates,
    includeAlreadyAssociatedCandidates,
    bulkCustomActivitySelection,
    bulkActivitySelection,
    candidatesRecommendationStatus,
    transferType,
  } = props;

  const activateClassName = 'activatedBulkOptionIcon';
  const candidateTransferIconClassName = `bulkActionIcon ${
    isBulkIconActivated && activateClassName
  } bulkCandidateTransfer`;

  const singleCandidateTransferIconClassName = styles.candidateTransferIcon;

  const onClick = () => {
    if (isBulkIconActivated)
      toggleAssociateCandidateModalStatus(true, {
        isBulkIconActivated,
        selectedCandidatesCount,
        isEnableCustomSelect,
        customSelectNumber,
        filterContextId,
        isAryaRecommendedSelectAllChecked,
        candidateIds,
        clearBulkActions,
        currentSelectedAllCandidates,
        includeAlreadyAssociatedCandidates,
        bulkCustomActivitySelection,
        bulkActivitySelection,
        candidatesRecommendationStatus,
        transferType,
      });
    else toggleAssociateCandidateModalStatus(true, { ...candidate, candidateIds, transferType });
  };
  return (
    <div>
      <CandidateTransferIcon
        onClick={onClick}
        className={transferType === 'bulk' ? candidateTransferIconClassName : singleCandidateTransferIconClassName}
        fillColor="#01cd75"
      />
    </div>
  );
};

export default CandidateAssociate;
