export const TOGGLE_ADD_CANDIDATE_VISIBLE_STATUS = 'TOGGLE_ADD_CANDIDATE_VISIBLE_STATUS';
export const TOGGLE_ASSOCIATE_CANDIDATE_MODAL = 'TOGGLE_ASSOCIATE_CANDIDATE_MODAL';

export function toggleAddCandidateDrawer(visible) {
  return {
    type: TOGGLE_ADD_CANDIDATE_VISIBLE_STATUS,
    payload: {
      visible,
    },
  };
}
export function toggleAssociateCandidateModal(visible, modalInfo) {
  return {
    type: TOGGLE_ASSOCIATE_CANDIDATE_MODAL,
    payload: {
      visible,
      modalInfo,
    },
  };
}
